import * as React from "react"
import { useRef } from "react"
import { ImageFluid } from "../../types/collection.props"
import Img from "gatsby-image/withIEPolyfill"
import "react-glidejs/dist/index.css"
import Glide from "react-glidejs"

const ImageSliderComponent = ({ data }) => {

  const gliderRef = useRef(null)

  const caruselData: ImageFluid[] = data

  return (
    <div className={"w-screen max-w-body h-carusel"}>

      <Glide
        ref={gliderRef}
        throttle={0}
        type="slider"
        hideArrows={caruselData.length === 1}
        customSlideAnimation={{
          timeout: 300,
          classNames: "fade"
        }}
        peek={0}
        perView={1}
        startAt={0}
        autoplay={4000}
        slideClassName="slider__frame"
        focusAt="center"
      >
        {caruselData.map((value, index) =>
          (
            <Img key={index} className={"w-full h-carusel"}
                 fluid={{ ...value.childImageSharp.fluid, aspectRatio: 1160 / 600 }} />
          ))}
      </Glide>
    </div>
  )

}

export default ImageSliderComponent
