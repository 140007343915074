import * as React from "react"
import {SelectedImages} from "../../types/collection.props"
import "./featured-glasses.component.scss"
import Img from "gatsby-image/withIEPolyfill"
import "typeface-alex-brush"

const FeaturedGlassesComponent = ({data}) => {
  const selectedImages: SelectedImages = data
  return (
    <div className="featured-glasses w-screen max-w-body mt-8">
      <div className="glass-box">
        <Img className="glass-box-image "
             fluid={{...selectedImages.imageOne.childImageSharp.fluid, aspectRatio: 560 / 360}}/>
        {/*<miraandme-image className="glass-box-image" [images]="productOne.product1Image"></miraandme-image>*/}
        {!!selectedImages?.titleOne &&
          <h3 className="glass-box-heading">{selectedImages.titleOne}</h3>
        }

      </div>
      <div className="glass-box">
        <Img className="glass-box-image w-full"
             fluid={{...selectedImages.imageTwo.childImageSharp.fluid, aspectRatio: 560 / 360}}/>
        {!!selectedImages?.titleOne &&
          <h3 className="glass-box-heading">{selectedImages.titleTwo}</h3>
        }

      </div>

    </div>
  )
}

export default FeaturedGlassesComponent
