import * as React from "react"
import "./highlight.scss"

const HighlightText = ({ data }) => {
  const detailText = true
  return (
    <>
      {!!data &&
      <div className="featured-text mt-16">
        <h3 className="featured-text-heading">
          Highlights
        </h3>
        {/*<p className="featured-text-text">{data}</p>*/}
      </div>
      }
    </>
  )
}
export default HighlightText
