import * as React from "react";
import { SelectedModel } from "../../types/collection.props";
import Img from "gatsby-image/withIEPolyfill";
import "./showcase-item.component.scss";

const ShowCaseItemComponet = (props) => {
  const value: SelectedModel = props.data.value;
  const index: number = props.data.index;
  return (
    <div className="relative" key={index}>
      <Img className={"w-full"} fluid={{ ...value.image.childImageSharp.fluid, aspectRatio: 1 / 1 }} />
      <div className=" overlay">
        {value?.labels?.map((value, index) => {
          switch (value) {
            case "isNew":
              return <span key={index} className="badge new">NEW</span>;
            case "isSale":
              return <span key={index} className="badge sale">SALE</span>;
            case "isTopModel":
              return <span key={index} className="badge top">TOP</span>;
            case "isPopular":
              return <span key={index} className="badge popular">POPULAR</span>;
            case "isTitan":
              return <span key={index} className="badge titan">TITAN</span>;
            case "isAcetat":
              return <span key={index} className="badge acetat">ACETAT</span>;
            case "isStainlessSteel":
              return <span key={index} className="badge stainless-steel">STAINLESS STEEL</span>;
            default:
              break;
          }
        })}
      </div>

      <div className={"detail"}>
        <span className={"title"}>{value.title}</span>
        <div className={"price"}>
          {
            (value.previousPrice && value.previousPrice !== "0") &&
            <span className={"old"}>{value.previousPrice}€</span>
          }
          {
            (value.currentPrice && value.currentPrice !== "0") &&
            <span className={"current"}>{value.currentPrice}€ UVP</span>
          }
        </div>
      </div>
    </div>
  );
};

export default ShowCaseItemComponet;
