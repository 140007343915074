import Home from "../components/layouts/home";
import * as React from "react";
import { graphql } from "gatsby";
import CollectionProps from "../types/collection.props";
import ImageSliderComponent from "../components/image-slider/image-slider.component";
import ShowCaseComponet from "../components/showcase/showcase.component";
import FeaturedGlassesComponent from "../components/featured/featured-glasses.component";
import HighlightText from "../components/text/highlight";

const Collection = ({ data }) => {
  const collectionData: CollectionProps = data;
  return (
    <Home>
      <ImageSliderComponent data={collectionData.collection.frontmatter.carouselImage} />
      <FeaturedGlassesComponent data={collectionData.collection.frontmatter.selectedImages} />
      <HighlightText data={collectionData.collection.frontmatter.text} />
      <ShowCaseComponet data={collectionData.collection.frontmatter.selectedModels} />
    </Home>
  );
};
export default Collection;

export const pageQuery = graphql`
query Collection($id: String!) {
  collection: markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      slug
      title
      text
      selectedModels {
        title
        currentPrice
        previousPrice
        labels
        image {
          childImageSharp {
            fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600], fit: CONTAIN) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      selectedImages {
        backgroundText
        titleTwo
        titleOne
        linkTwo
        linkOne
        imageTwo {
          childImageSharp {
            fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600, 800], fit: COVER, maxWidth: 560, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageOne {
          childImageSharp {
            fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600, 800], fit: COVER, maxWidth: 560, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      carouselImage {
        childImageSharp {
          fluid(webpQuality: 70, jpegQuality: 80, srcSetBreakpoints: [300, 600, 800, 1200, 1600, 1920], fit: CONTAIN, background: "rgba(0,0,0,0)", maxWidth: 1920, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`;
