import * as React from "react";
import { SelectedModel } from "../../types/collection.props";
import ShowCaseItemComponet from "./showcase-item.component";
import "./showcase.component.scss";

const ShowCaseComponet = ({ data }) => {
  const showcase: SelectedModel[] = data;
  return (
    <div className="showcase-container">
      {showcase.map(((value, index) => {
        return (<ShowCaseItemComponet key={index} data={{ value, index }} />);
      }))}
    </div>
  );
};

export default ShowCaseComponet;
